.privacy {
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 60px 20px; */
  position: relative;
  overflow: hidden;
}

.privacy-content {
  position: relative;
  z-index: 2;
  max-width: 1200px;
  width: 100%;
  padding: 0 15px;
}

.privacy-content h1 {
  font-size: 72px;
  text-align: center;
  font-weight: 900;
  color: #064670;
  margin-bottom: 20px;
  line-height: 1.2;
}

.privacy-content h1 .accent {
  color: #6fcac5;
  position: relative;
  display: inline-block;
}

.privacy-date {
  font-size: 18px;
  font-weight: 600;
  color: #6c757d;
  margin-bottom: 30px;
}

.privacy-content p {
  font-size: 20px;
  color: #6c757d;
  max-width: 1200px;
  margin: 0 auto 20px;
  line-height: 1.6;
}

/* Responsive Design */
@media (max-width: 768px) {
  .privacy-content h1 {
    font-size: 48px;
  }

  .privacy-content p {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .privacy-content h1 {
    font-size: 36px;
  }

  .privacy-content p {
    font-size: 16px;
  }
}