body {
  font-family: "Outfit", sans-serif;
  background: #ffffff;
  color: #064670;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

/* Apply blur to main content when menu is open (controlled by JS) */
body.blur-content > *:not(.header) {
  filter: blur(5px);
  transition: filter 0.3s ease;
}

.App {
  min-height: 100vh;
}

/* Header Styles */
.header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 60px; /* Reduced vertical padding from 20px to 10px */
  background: linear-gradient(90deg, #064670, #6fcac5);
  box-shadow: 0 0 20px rgba(111, 202, 197, 0.5);
  z-index: 1000;
  border-radius: 15px;
  margin: 5px 20px; /* Reduced vertical margin from 10px to 5px */
  overflow: hidden;
}

.header .logo {
  width: 120px; /* Reduced from 150px to 120px */
  height: auto;
  display: block;
}

/* Hamburger Icon (hidden by default, shown on smaller screens) */
.hamburger {
  display: none;
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
  z-index: 1001;
}

.hamburger.hidden {
  display: none;
}

/* Navigation styles for larger screens */
.header nav {
  display: flex;
  align-items: center;
}

.header nav ul {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.header nav ul li {
  margin-left: 30px; /* Reduced from 40px to 30px for tighter spacing */
}

.header nav ul li a {
  color: black;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px; /* Added font-size to reduce text size */
  text-transform: uppercase;
  transition: color 0.3s, text-shadow 0.3s;
}

.header nav ul li a:hover {
  color: #6fcac5;
  text-shadow: 0 0 10px #6fcac5;
}

/* Hide menu header and dropdown arrow on larger screens */
.menu-header,
.dropdown-arrow {
  display: none;
}

/* Hero Styles */
.hero {
  /* min-height: 100vh; */
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* padding: 40px 20px; */
  position: relative;
  overflow: hidden;
}

.hero-content {
  position: relative;
  z-index: 2;
  max-width: 1200px;
  width: 100%;
  padding: 0 15px;
}

.hero-content h1 {
  font-size: 72px;
  font-weight: 900;
  color: #064670;
  margin-bottom: 20px;
  line-height: 1.2;
}

.hero-content h1 .accent {
  color: #6fcac5;
  position: relative;
  display: inline-block;
}

.hero-content h1 .accent::before {
  content: "";
  position: absolute;
  left: -40px;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background: #6fcac5;
  border-radius: 50%;
}

.hero-content h1 .accent::after {
  content: "→";
  position: absolute;
  left: -60px;
  top: 50%;
  transform: translateY(-50%);
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
}

.hero-content p {
  font-size: 20px;
  color: #6c757d;
  max-width: 800px;
  margin: 0 auto 30px;
  line-height: 1.6;
}

.explore-button {
  padding: 12px 30px;
  font-size: 18px;
  font-weight: 700;
  background: #6fcac5;
  border: none;
  border-radius: 50px;
  color: #064670;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}

.explore-button:hover {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgba(111, 202, 197, 0.5);
}

.explore-button span {
  margin-left: 10px;
}

.hero-badges {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
  flex-wrap: wrap;
}

.badge {
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 20px;
  padding: 10px 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-weight: 600;
  color: #064670;
  gap: 15px;
}

.badge.solutions {
  background: #6fcac5;
  color: #064670;
}

.badge.experience {
  background: #064670;
  color: #ffffff;
}

.badge-icon {
  width: 30px;
  height: 30px;
  border-radius: 10%;
  margin-right: 15px;
}

.hero-images {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
  flex-wrap: wrap;
}

.hero-images img {
  width: 200px;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Services Styles */
.services {
  padding: 100px 60px;
  /* 100px */
  text-align: center;
  background: #ffffff;
}

.services h2 {
  font-size: 48px;
  font-weight: 800;
  color: #064670;
  margin-bottom: 60px;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
}

.service-item {
  padding: 20px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  box-shadow: 0 0 30px rgba(111, 202, 197, 0.5);
  transition: transform 0.3s;
}

.service-item:hover {
  transform: translateY(-5px);
}

.service-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  filter: brightness(1.2);
}

.service-item h3 {
  font-size: 24px;
  margin: 15px 0;
  color: #064670;
}

.service-item p {
  font-size: 16px;
  color: #6c757d;
  line-height: 1.5;
}

/* About Styles */
.about {
  padding: 100px 60px;
  background: #ffffff;
}

.about-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  gap: 60px;
  padding: 30px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  box-shadow: 0 0 30px rgba(111, 202, 197, 0.5);
}

.about-text {
  flex: 1;
}

.about-text h2 {
  font-size: 48px;
  font-weight: 800;
  color: #064670;
  margin-bottom: 20px;
}

.about-text p {
  font-size: 20px;
  color: #6c757d;
  line-height: 1.6;
}

.about-content img {
  width: 50%;
  max-width: 500px;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 0 30px rgba(111, 202, 197, 0.5);
  object-fit: cover;
}

/* Testimonials Styles */
.testimonials {
  padding: 100px 60px;
  text-align: center;
  background: #ffffff;
}

.testimonials h2 {
  font-size: 48px;
  font-weight: 800;
  color: #064670;
  margin-bottom: 40px;
}

.testimonial-item {
  max-width: 800px;
  margin: 0 auto;
  padding: 30px;
  /* background: rgba(255, 255, 255, 0.9); */
  /* border-radius: 15px; */
  box-shadow: 0 0 30px rgba(111, 202, 197, 0.5);
}

.testimonial-item p {
  font-size: 22px;
  font-style: italic;
  color: #6c757d;
}

.testimonial-item span {
  font-size: 18px;
  color: #064670;
}

/* Contact Styles */
.contact {
  padding: 80px 40px;
  background: #ffffff;
  text-align: center;
}

.contact h2 {
  font-size: 48px;
  font-weight: 800;
  color: #064670;
  margin-bottom: 40px;
}

.contact-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  gap: 40px;
}

.contact-details {
  flex: 1;
  text-align: left;
}

.contact-details h3 {
  font-size: 28px;
  font-weight: 700;
  color: #064670;
  margin-bottom: 20px;
}

.contact-info h4 {
  font-size: 20px;
  font-weight: 600;
  color: #064670;
  margin: 15px 0 5px;
}

.contact-info p {
  font-size: 16px;
  color: #6c757d;
  line-height: 1.5;
  margin-bottom: 10px;
}

.contact-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-form input,
.contact-form textarea {
  padding: 15px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background: #f9f9f9;
  width: 100%;
  box-sizing: border-box;
}

.contact-form textarea {
  min-height: 150px;
  resize: vertical;
}

.contact-form button {
  padding: 12px;
  font-size: 18px;
  background: #6fcac5;
  color: #064670;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s;
}

.contact-form button:hover {
  background: #5ab5b0;
}

/* Store Styles */
.store {
  padding: 80px 40px;
  background: #ffffff;
  text-align: center;
}

.store h2 {
  font-size: 48px;
  font-weight: 800;
  color: #064670;
  /* text-shadow: 0 0 15px rgba(6, 70, 112, 0.7); */
  margin-bottom: 20px;
}

.store p {
  font-size: 20px;
  color: #6c757d;
  margin-bottom: 30px;
}

.store-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.store-buttons a {
  display: inline-block;
}

.store-buttons img {
  height: 60px;
  width: auto;
  transition: transform 0.3s, box-shadow 0.3s;
}

.store-buttons img:hover {
  transform: scale(1.05);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

/* Footer Styles */
.footer {
  padding: 60px 20px;
  background: linear-gradient(180deg, #064670, #6fcac5);
  /* background: linear-gradient(90deg, #064670, #6fcac5); */
  text-align: center;
  box-shadow: 0 0 20px rgba(111, 202, 197, 0.5);
}

.footer p {
  font-size: 18px;
  color: #fff;
  margin-bottom: 20px;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.social-links a {
  color: #fff;
  text-decoration: none;
}

.social-icon {
  width: 24px;
  height: 24px;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .header {
    padding: 10px 40px; /* Adjusted padding */
    border-radius: 12px;
    margin: 5px 15px; /* Adjusted margin */
  }

  .header .logo {
    width: 110px; /* Adjusted logo size */
  }

  .header nav ul li {
    margin-left: 25px; /* Adjusted spacing */
  }

  .hero-content {
    padding: 0 10px;
  }

  .hero-content h1 {
    font-size: 60px;
  }

  .services {
    padding: 80px 40px;
  }

  .services h2 {
    font-size: 40px;
  }

  .services-grid {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }

  .service-item img {
    height: 180px;
  }

  .about {
    padding: 80px 40px;
  }

  .about-content {
    gap: 40px;
  }

  .about-text h2 {
    font-size: 40px;
  }

  .about-text p {
    font-size: 18px;
  }

  .about-content img {
    width: 45%;
    max-width: 400px;
  }

  .testimonials {
    padding: 80px 40px;
  }

  .testimonials h2 {
    font-size: 40px;
  }

  .contact {
    padding: 60px 30px;
  }

  .contact h2 {
    font-size: 40px;
  }

  .contact-content {
    gap: 30px;
  }

  .store {
    padding: 60px 30px;
  }

  .store h2 {
    font-size: 40px;
  }

  .footer {
    padding: 50px 20px;
  }
}

@media (max-width: 1024px) {
  .header nav ul li {
    margin-left: 20px; /* Adjusted spacing */
  }

  .hero-content h1 {
    font-size: 50px;
  }

  .hero-content h1 .accent::before,
  .hero-content h1 .accent::after {
    left: -35px;
    width: 25px;
    height: 25px;
    font-size: 20px;
  }

  .hero-content p {
    font-size: 18px;
    max-width: 700px;
  }

  .services-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
  }

  .service-item img {
    height: 160px;
  }

  .service-item h3 {
    font-size: 22px;
  }

  .service-item p {
    font-size: 15px;
  }

  .about-content img {
    width: 40%;
    max-width: 350px;
  }

  .testimonials h2 {
    font-size: 36px;
  }

  .testimonial-item {
    padding: 20px;
  }

  .contact-details h3 {
    font-size: 24px;
  }

  .contact-info h4 {
    font-size: 18px;
  }

  .contact-info p {
    font-size: 15px;
  }
}

@media (max-width: 768px) {
  .header {
    padding: 15px 20px;
    border-radius: 10px;
    margin: 6px 10px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header .logo {
    width: 110px;
  }

  /* Show hamburger icon on tablet and mobile */
  .hamburger {
    display: block;
    position: relative;
    right: 0;
    font-size: 24px;
    color: #ffffff;
    cursor: pointer;
    z-index: 1001;
  }

  /* Navigation styles for mobile/tablet */
  .header nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 60%;
    height: 100vh;
    background: #ffffff;
    transform: translateX(-100%);
    opacity: 0;
    visibility: hidden;
    transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  /* Show navigation when toggled */
  .header nav.nav-open {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }

  /* Overlay for the remaining 40% of the screen */
  .header nav.nav-open::after {
    content: "";
    position: fixed;
    top: 0;
    left: 60%;
    width: 40%;
    height: 100vh;
    /* background: rgba(0, 0, 0, 0.5); */
    z-index: 999;
  }

  /* Menu Header (Logo and Close Icon) */
  .menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: 100%;
  }

  .menu-logo {
    width: 110px;
    height: auto;
  }

  .close-icon {
    font-size: 24px;
    color: #000000;
    cursor: pointer;
  }

  .header nav ul {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    margin: 0;
  }

  .header nav ul li {
    margin: 20px 0;
  }

  .header nav ul li a {
    font-size: 18px;
    color: #000000;
    text-transform: none;
    font-weight: 400;
  }

  .header nav ul li a:hover {
    color: #6fcac5;
    text-shadow: none;
  }

  /* Dropdown styling */
  .header nav ul li.dropdown {
    display: flex;
    align-items: center;
  }

  .dropdown-arrow {
    display: inline-block;
    margin-left: 10px;
    font-size: 14px;
    color: #000000;
  }

  .hero {
    padding: 30px 15px;
  }

  .hero-content h1 {
    font-size: 36px;
  }

  .hero-content h1 .accent::before,
  .hero-content h1 .accent::after {
    left: -30px;
    width: 20px;
    height: 20px;
    font-size: 18px;
  }

  .hero-content p {
    font-size: 16px;
    max-width: 90%;
  }

  .explore-button {
    padding: 10px 25px;
    font-size: 16px;
  }

  .hero-badges {
    gap: 15px;
  }

  .badge {
    padding: 8px 15px;
    font-size: 14px;
  }

  .badge-icon {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  .hero-images img {
    width: 150px;
    height: 225px;
  }

  .services {
    padding: 60px 20px;
  }

  .services h2 {
    font-size: 32px;
    margin-bottom: 40px;
  }

  .services-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .service-item {
    padding: 15px;
  }

  .service-item img {
    height: 180px;
  }

  .service-item h3 {
    font-size: 20px;
  }

  .service-item p {
    font-size: 14px;
  }

  .about {
    padding: 10px 20px; 
    /* 60px */
  }

  .about-content {
    flex-direction: column;
    text-align: center;
    gap: 30px;
  }

  .about-text h2 {
    font-size: 32px;
  }

  .about-text p {
    font-size: 16px;
  }

  .about-content img {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  .testimonials {
    padding: 60px 20px;
  }

  .testimonials h2 {
    font-size: 32px;
    margin-bottom: 30px;
  }

  .testimonial-item {
    padding: 20px;
  }

  .testimonial-item p {
    font-size: 18px;
  }

  .contact {
    padding: 50px 20px;
  }

  .contact h2 {
    font-size: 32px;
  }

  .contact-content {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .contact-details {
    text-align: center;
    width: 100%;
  }

  .contact-form {
    width: 100%;
  }

  .contact-form input,
  .contact-form textarea {
    padding: 12px;
    font-size: 14px;
  }

  .contact-form button {
    padding: 10px;
    font-size: 16px;
  }

  .store {
    padding: 50px 20px;
  }

  .store h2 {
    font-size: 32px;
  }

  .store p {
    font-size: 16px;
  }

  .store-buttons img {
    height: 50px;
  }

  .footer {
    padding: 40px 15px;
  }

  .social-links {
    gap: 15px;
  }

  .footer p {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .header {
    padding: 10px 15px;
    border-radius: 8px;
    margin: 4px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header .logo {
    width: 90px;
  }

  .hamburger {
    display: block;
    position: relative;
    right: 0;
    font-size: 20px;
    color: #ffffff;
    cursor: pointer;
    z-index: 1001;
  }

  .menu-logo {
    width: 90px;
  }

  .header nav ul li a {
    font-size: 16px;
  }

  .hero {
    padding: 20px 10px;
  }

  .hero-content h1 {
    font-size: 28px;
  }

  .hero-content h1 .accent::before,
  .hero-content h1 .accent::after {
    left: -25px;
    width: 15px;
    height: 15px;
    font-size: 14px;
  }

  .hero-content p {
    font-size: 14px;
  }

  .explore-button {
    padding: 8px 20px;
    font-size: 14px;
  }

  .hero-images img {
    width: 120px;
    height: 180px;
  }

  .services {
    padding: 40px 15px;
  }

  .services h2 {
    font-size: 28px;
    margin-bottom: 30px;
  }

  .service-item img {
    height: 160px;
  }

  .service-item h3 {
    font-size: 18px;
  }

  .service-item p {
    font-size: 13px;
  }

  .about {
    padding: 40px 15px;
  }

  .about-text h2 {
    font-size: 28px;
  }

  .about-text p {
    font-size: 14px;
  }

  .about-content img {
    max-width: 250px;
  }

  .testimonials {
    padding: 40px 15px;
  }

  .testimonials h2 {
    font-size: 28px;
  }

  .testimonial-item p {
    font-size: 16px;
  }

  .testimonial-item span {
    font-size: 14px;
  }

  .contact {
    padding: 40px 15px;
  }

  .contact h2 {
    font-size: 28px;
  }

  .contact-details h3 {
    font-size: 22px;
  }

  .contact-info h4 {
    font-size: 16px;
  }

  .contact-info p {
    font-size: 14px;
  }

  .contact-form input,
  .contact-form textarea {
    padding: 10px;
    font-size: 13px;
  }

  .contact-form button {
    padding: 8px;
    font-size: 14px;
  }

  .store {
    padding: 40px 15px;
  }

  .store h2 {
    font-size: 28px;
  }

  .store p {
    font-size: 14px;
  }

  .store-buttons {
    flex-direction: column;
    gap: 15px;
  }

  .store-buttons img {
    height: 40px;
  }

  .footer {
    padding: 30px 10px;
  }

  .social-links {
    gap: 10px;
  }

  .footer p {
    font-size: 14px;
  }

  .social-icon {
    width: 20px;
    height: 20px;
  }
}

/* Header Container */
.header-container {
  background: #ffffff;
  padding: 10px 0;
  position: sticky;
  top: 0;
  z-index: 1000;
}

/* Header Styles */
.header {
  padding: 10px 20px;
  background: transparent;
  box-shadow: none;
  border-radius: 0;
  margin: 0;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

/* First Line: Logo, SignUp, and Login */
.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header .logo {
  width: 100px;
  height: auto;
  display: block;
}

/* SignUp and Login Buttons */
.auth-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.signup-btn,
.login-btn {
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
}

.signup-btn {
  background: #064670;
  color: #ffffff;
}

.signup-btn:hover {
  background: #6fcac5;
}

.login-btn {
  background: transparent;
  color: #064670;
  border: 1px solid #064670;
}

.login-btn:hover {
  background: #064670;
  color: #ffffff;
}

/* Hamburger Icon */
.hamburger {
  display: none;
  font-size: 24px;
  color: #064670;
  cursor: pointer;
  z-index: 1001;
}

/* Navigation Styles for Larger Screens */
.header nav {
  background: #ffffff;
  padding: 10px 20px;
  border-radius: 10px;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); Subtle shadow for highlighted effect */
  margin-top: 10px;
}

.nav-list {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%;
  justify-content: space-around;
}

.nav-item {
  position: relative;
}

.nav-link {
  color: #000000; /* Black text */
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  transition: color 0.3s;
}

.nav-link:hover {
  color: #6fcac5;
}

/* Dropdown Styles */
.dropdown {
  position: relative;
}

.dropdown-arrow {
  font-size: 10px;
  margin-left: 5px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  list-style: none;
  padding: 10px 0;
  margin: 5px 0 0 0;
  min-width: 150px;
  z-index: 1000;
}

.dropdown-link {
  color: #000000;
  text-decoration: none;
  font-size: 14px;
  padding: 8px 15px;
  display: block;
}

.dropdown-link:hover {
  background: #f5f5f5;
  color: #6fcac5;
}

/* WhatsApp Icon */
.whatsapp-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  width: 50px;
  height: 50px;
  background: #25d366;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
}

.whatsapp-icon:hover {
  transform: scale(1.1);
}

.whatsapp-icon img {
  width: 30px;
  height: 30px;
}

/* Body Centering with Margins */
/* body {
  font-family: "Times New Roman", serif;
  background: #ffffff;
  color: #064670;
  overflow-x: hidden;
  margin: 0;
  padding: 0 20px;
  box-sizing: border-box;
} */

/* Center all main sections */
.hero,
.services,
.about,
.testimonials,
.contact,
.store {
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

/* Responsive Design */
@media (max-width: 1024px) {
  /* Show hamburger icon on tablets and smaller screens */
  .hamburger {
    display: block;
  }

  /* Hide navigation by default on tablets and smaller screens */
  .header nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 60%;
    height: 100vh;
    background: #ffffff;
    transform: translateX(-100%);
    opacity: 0;
    visibility: hidden;
    transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-top: 0;
    box-shadow: none;
  }

  /* Show navigation when toggled */
  .header nav.nav-open {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }

  /* Overlay for the remaining 40% of the screen */
  .header nav.nav-open::after {
    content: "";
    position: fixed;
    top: 0;
    left: 60%;
    width: 40%;
    height: 100vh;
    /* background: rgba(0, 0, 0, 0.5); */
    z-index: 999;
  }

  .nav-list {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    margin: 0;
  }

  .nav-item {
    margin: 20px 0;
  }

  .nav-link {
    font-size: 18px;
    color: #000000;
  }

  .nav-link:hover {
    color: #6fcac5;
  }

  .dropdown-menu {
    position: static;
    box-shadow: none;
    padding: 10px 0 0 20px;
  }

  .auth-buttons {
    gap: 8px;
  }

  .signup-btn,
  .login-btn {
    padding: 6px 15px;
    font-size: 12px;
  }

  .whatsapp-icon {
    width: 40px;
    height: 40px;
  }

  .whatsapp-icon img {
    width: 24px;
    height: 24px;
  }
}

@media (max-width: 480px) {
  .header-top {
    flex-wrap: wrap;
    gap: 10px;
  }

  .auth-buttons {
    flex-direction: row;
    gap: 5px;
  }

  .signup-btn,
  .login-btn {
    padding: 5px 10px;
    font-size: 11px;
  }

  .header .logo {
    width: 90px;
  }

  .hamburger {
    font-size: 20px;
  }

  .whatsapp-icon {
    width: 35px;
    height: 35px;
  }

  .whatsapp-icon img {
    width: 20px;
    height: 20px;
  }
}

.social-link {
  color: #fff; /* Default color before applying native colors */
  text-decoration: none;
}

/* Twitter/X - Black */
.social-link.twitter {
  color: #000000; /* Native color for Twitter/X */
}

.social-link.twitter:hover {
  color: #000000; /* Twitter's blue for hover */
}

/* LinkedIn - Blue */
.social-link.linkedin {
  color: #0077b5; /* Native color for LinkedIn */
}

.social-link.linkedin:hover {
  color: #005582; /* Darker shade for hover */
}

/* Facebook - Blue */
.social-link.facebook {
  color: #1877f2; /* Native color for Facebook */
}

.social-link.facebook:hover {
  color: #145dbf; /* Darker shade for hover */
}

/* Instagram - Gradient */
.social-link.instagram {
  /* Gradient is applied via SVG fill, so no color needed here */
}

.social-link.instagram:hover {
  /* Optional: Add a slight brightness effect on hover */
  filter: brightness(1.2);
}

/* Header Container */
.header-container {
  background: #ffffff;
  padding: 10px 0;
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%; /* Ensure full width */
}

/* Header Styles */
.header {
  padding: 10px 20px;
  background: transparent;
  box-shadow: none;
  border-radius: 0;
  margin: 0;
  max-width: 1200px;
  margin: 0 auto;
}

/* Header Content: Single Line for Larger Screens */
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #ffffff; /* Solid white background */
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for highlighted effect */
}

/* Logo */
.header .logo {
  width: 100px;
  height: auto;
  display: block;
}

/* Navigation Styles */
.header nav {
  flex: 1;
  margin: 0 20px;
}

.nav-list {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  justify-content: center;
  gap: 20px;
}

.nav-item {
  position: relative;
}

.nav-link {
  color: #000000; /* Black text */
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  transition: color 0.3s;
}

.nav-link:hover {
  color: #6fcac5;
}

/* Dropdown Styles */
.dropdown {
  position: relative;
}

.dropdown-arrow {
  font-size: 10px;
  margin-left: 5px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  list-style: none;
  padding: 10px 0;
  margin: 5px 0 0 0;
  min-width: 150px;
  z-index: 1000;
}

.dropdown-link {
  color: #000000;
  text-decoration: none;
  font-size: 14px;
  padding: 8px 15px;
  display: block;
}

.dropdown-link:hover {
  background: #f5f5f5;
  color: #6fcac5;
}

/* SignUp and Login Buttons */
.auth-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.signup-btn,
.login-btn {
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
}

.signup-btn {
  background: #064670;
  color: #ffffff;
}

.signup-btn:hover {
  background: #6fcac5;
}

.login-btn {
  background: transparent;
  color: #064670;
  border: 1px solid #064670;
}

.login-btn:hover {
  background: #064670;
  color: #ffffff;
}

/* Hamburger Icon */
.hamburger {
  display: none;
  font-size: 24px;
  color: #064670;
  cursor: pointer;
  z-index: 1001;
}

/* WhatsApp Icon */
.whatsapp-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  width: 50px;
  height: 50px;
  background: #25d366;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
}

.whatsapp-icon:hover {
  transform: scale(1.1);
}

.whatsapp-icon img {
  width: 30px;
  height: 30px;
}

/* Body Centering with Margins */
body {
  /* font-family: "Times New Roman", serif; */
  background: #ffffff;
  color: #064670;
  overflow-x: hidden;
  margin: 0;
  padding: 0 20px;
  box-sizing: border-box;
}

/* Center all main sections */
.hero,
.services,
.about,
.testimonials,
.contact,
.store {
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

/* Responsive Design */
@media (max-width: 1024px) {
  /* Show hamburger icon on tablets and smaller screens */
  .hamburger {
    display: block;
  }

  /* Hide navigation links by default on smaller screens */
  .header nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 60%;
    height: 100vh;
    background: #ffffff;
    transform: translateX(-100%);
    opacity: 0;
    visibility: hidden;
    transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  /* Show navigation when toggled */
  .header nav.nav-open {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }

  /* Overlay for the remaining 40% of the screen */
  .header nav.nav-open::after {
    content: "";
    position: fixed;
    top: 0;
    left: 60%;
    width: 40%;
    height: 100vh;
    /* background: rgba(0, 0, 0, 0.5); */
    z-index: 999;
  }

  .nav-list {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    margin: 0;
  }

  .nav-item {
    margin: 20px 0;
  }

  .nav-link {
    font-size: 18px;
    color: #000000;
  }

  .nav-link:hover {
    color: #6fcac5;
  }

  .dropdown-menu {
    position: static;
    box-shadow: none;
    padding: 10px 0 0 20px;
  }

  /* Adjust header content for smaller screens */
  .header-content {
    justify-content: space-between;
    padding: 10px 15px;
  }

  .auth-buttons {
    gap: 8px;
  }

  .signup-btn,
  .login-btn {
    padding: 6px 15px;
    font-size: 12px;
  }

  .whatsapp-icon {
    width: 40px;
    height: 40px;
  }

  .whatsapp-icon img {
    width: 24px;
    height: 24px;
  }
}

@media (max-width: 480px) {
  .header-content {
    flex-wrap: wrap;
    gap: 10px;
  }

  .auth-buttons {
    flex-direction: row;
    gap: 5px;
  }

  .signup-btn,
  .login-btn {
    padding: 5px 10px;
    font-size: 11px;
  }

  .header .logo {
    width: 90px;
  }

  .hamburger {
    font-size: 20px;
  }

  .whatsapp-icon {
    width: 35px;
    height: 35px;
  }

  .whatsapp-icon img {
    width: 20px;
    height: 20px;
  }
}

.testimonials-carousel {
  max-width: 800px;
  margin: 0 auto;
}

.store-button {
  background: none;
  border: none;
  cursor: pointer;
}

.staffing-services {
  padding: 100px 60px;
  background: #ffffff;
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

.staffing-content {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  box-shadow: 0 0 30px rgba(111, 202, 197, 0.5);
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.staffing-text-top {
  display: grid;
  grid-template-columns: 40% 1fr;
  gap: 60px;
  align-items: start;
}

.staffing-image {
  width: 100%;
  height: 600px;
  border-radius: 15px;
  box-shadow: 0 0 30px rgba(111, 202, 197, 0.5);
  object-fit: cover;
}

.staffing-text-top-content {
  padding: 0;
}

.staffing-text-bottom {
  width: 100%;
}

.staffing-text-top-content h2,
.staffing-text-bottom h2 {
  font-size: 48px;
  font-weight: 800;
  color: #064670;
  margin-bottom: 20px;
}

.staffing-text-top-content h3,
.staffing-text-bottom h3 {
  font-size: 24px;
  font-weight: 700;
  color: #064670;
  margin: 30px 0 15px;
  border-bottom: 2px solid #6fcac5;
  padding-bottom: 5px;
}

.staffing-text-top-content p,
.staffing-text-bottom p {
  font-size: 20px;
  color: #6c757d;
  line-height: 1.6;
  margin-bottom: 20px;
}

.staffing-text-top-content ul,
.staffing-text-bottom ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 20px;
}

.staffing-text-top-content ul li,
.staffing-text-bottom ul li {
  font-size: 20px;
  color: #6c757d;
  line-height: 1.6;
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
}

.staffing-text-top-content ul li:before,
.staffing-text-bottom ul li:before {
  content: "→";
  color: #6fcac5;
  position: absolute;
  left: 0;
  font-size: 20px;
  font-weight: 700;
}

@media (max-width: 1200px) {
  .staffing-services {
    padding: 80px 40px;
  }

  .staffing-text-top {
    grid-template-columns: 35% 1fr;
    gap: 40px;
  }

  .staffing-text-top-content h2,
  .staffing-text-bottom h2 {
    font-size: 40px;
  }

  .staffing-text-top-content h3,
  .staffing-text-bottom h3 {
    font-size: 22px;
  }

  .staffing-text-top-content p,
  .staffing-text-bottom p,
  .staffing-text-top-content ul li,
  .staffing-text-bottom ul li {
    font-size: 18px;
  }

  .staffing-image {
    height: 550px;
  }
}

@media (max-width: 1024px) {
  .staffing-text-top-content h2,
  .staffing-text-bottom h2 {
    font-size: 36px;
  }

  .staffing-text-top-content h3,
  .staffing-text-bottom h3 {
    font-size: 20px;
  }

  .staffing-text-top-content p,
  .staffing-text-bottom p,
  .staffing-text-top-content ul li,
  .staffing-text-bottom ul li {
    font-size: 16px;
  }

  .staffing-image {
    height: 500px;
  }
}

@media (max-width: 768px) {
  .staffing-services {
    padding: 60px 20px;
  }

  .staffing-content {
    gap: 30px;
    padding: 20px;
  }

  .staffing-text-top {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .staffing-image {
    width: 100%;
    max-width: 400px;
    height: 300px;
    margin: 0 auto;
  }

  .staffing-text-top-content,
  .staffing-text-bottom {
    text-align: center;
  }

  .staffing-text-top-content h2,
  .staffing-text-bottom h2 {
    font-size: 32px;
  }

  .staffing-text-top-content h3,
  .staffing-text-bottom h3 {
    font-size: 20px;
  }

  .staffing-text-top-content p,
  .staffing-text-bottom p,
  .staffing-text-top-content ul li,
  .staffing-text-bottom ul li {
    font-size: 16px;
  }

  .staffing-text-top-content ul li,
  .staffing-text-bottom ul li {
    padding-left: 25px;
  }

  .staffing-text-top-content ul li:before,
  .staffing-text-bottom ul li:before {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .staffing-services {
    padding: 40px 15px;
  }

  .staffing-content {
    gap: 20px;
    padding: 15px;
  }

  .staffing-image {
    max-width: 300px;
    height: 200px;
  }

  .staffing-text-top-content h2,
  .staffing-text-bottom h2 {
    font-size: 28px;
  }

  .staffing-text-top-content h3,
  .staffing-text-bottom h3 {
    font-size: 18px;
    margin: 20px 0 10px;
  }

  .staffing-text-top-content p,
  .staffing-text-bottom p,
  .staffing-text-top-content ul li,
  .staffing-text-bottom ul li {
    font-size: 14px;
  }

  .staffing-text-top-content ul li,
  .staffing-text-bottom ul li {
    padding-left: 20px;
    margin-bottom: 8px;
  }

  .staffing-text-top-content ul li:before,
  .staffing-text-bottom ul li:before {
    font-size: 14px;
  }
}